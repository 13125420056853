import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef, useMemo } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
  Grid,
  DialogContent,
  Stack,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import OpenInBrowser from '@mui/icons-material/OpenInBrowser';
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',

  bgcolor: 'background.paper',

  p: 4,
  borderRadius: '25px',
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// ----------------------------------------------------------------------

export default function PotentialUsers() {
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get('/common/potentialUsers')
      .then((r) => {
        console.log(r.data.data.users);
        setData(r.data.data.users);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState('');
  const [data, setData] = useState([]);
  const [userData, setUser] = useState('');
  const [id, setId] = useState('');
  const [note, setNote] = useState('');

  const user = useSelector((state) => state.auth.user);
  console.log({ userData });
  const updateContact = async (id, con) => {
    const userDetails = {
      contactedBy: user?._id,
    };

    if (con === 'pending') {
      userDetails.status = 'contacted';
    } else {
      userDetails.status = 'pending';
    }
    console.log({ userDetails });
    try {
      const res = await api.patch(`/common/potentialUsers/${id}`, {
        userDetails,
      });
      console.log({ res });
      //   setData(res.data.data);
      //   handleCloseA();
    } catch (err) {
      console.log(err);
    }
  };

  const deletePotentialUser = async (id) => {
    try {
      const r = await api.delete(`/common/potentialUsers/${id}`);

      setData(r.data.data.users);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    try {
      if (!note) {
        setOpen(false);
        return;
      }
      const userDetails = {
        note,
      };
      console.log({ userDetails });

      const res = await api.patch(`/common/potentialUsers/${userData?._id}`, {
        userDetails,
      });
      console.log({ res });
      setData(res.data.data.users);
      setOpen(false);
      //   setData(res.data.data);
      //   handleCloseA();
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'viewedDate',
        headerName: 'viewedDate At',
        description: 'viewedDate',
        type: 'date',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return `${new Date(params.value).toDateString()} - ${new Date(
            params.value
          ).toLocaleTimeString()}`;
        },
      },
      {
        field: 'firstName',
        headerName: 'Name',
        description: 'name',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email',
        description: 'email',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'countryCode',
        headerName: 'Country',
        description: 'countryCode',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'mobile',
        headerName: 'Mobile',
        description: 'mobile',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'conference',
        headerName: 'Conference',
        description: 'conference',
        type: 'string',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return params?.value?.title;
        },
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            // <GridActionsCellItem
            //   icon={
            //     <Tooltip title="Delete Department">
            //       <Delete />
            //     </Tooltip>
            //   }
            //   label="Delete"
            //   //   onClick={openDeleteDialog(params.id, params.row)}
            // />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Contact">
                  {params?.row?.status === 'contacted' ? (
                    <Switch
                      onClick={() => {
                        updateContact(params?.row?._id, params?.row?.status);
                      }}
                      checked
                    />
                  ) : (
                    <Switch
                      onClick={() => {
                        updateContact(params?.row?._id, params?.row?.status);
                      }}
                    />
                  )}
                </Tooltip>
              }
              label="Contact"
              //   onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <Edit />
                </Tooltip>
              }
              label="Edit"
              onClick={() => {
                setUser(params.row);
                setNote(params?.row?.note);
                setOpen(true);
              }}
              // showInMenu
            />,

            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Review"
              onClick={() => {
                deletePotentialUser(params.id);
              }}
              // showInMenu
            />,
          ];
        },
      },
    ],
    []
  );
  return (
    <Page title="Potential Users">
      <Container>
        <h1>Potential Users</h1>
        <h3 sx={{ mb: 3 }}>These are the users. Requesting for booking.</h3>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            mt: 1,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      {open && (
        <Dialog
          sx={style}
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit User Information
              </Typography>
              <Button autoFocus color="inherit" onClick={onSubmit}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Name</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="p">
                  {userData.firstName} {userData.lastName}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Email</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="p">{userData.email}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Mobile</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="p">{userData?.mobile}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Conference</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography sx={{ wordBreak: 'break-all' }} variant="p">
                  {userData?.conference?.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Start Date</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography sx={{ wordBreak: 'break-all' }} variant="p">
                  {new Date(userData?.conference?.startDate).toDateString()}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">End Date</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography sx={{ wordBreak: 'break-all' }} variant="p">
                  {new Date(userData?.conference?.endDate).toDateString()}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Viewed Date</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography sx={{ wordBreak: 'break-all' }} variant="p">
                  {`${new Date(
                    userData?.viewedDate
                  ).toLocaleDateString()} ${new Date(
                    userData?.viewedDate
                  ).toLocaleTimeString()}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Profession</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="p">{userData?.profession}</Typography>
              </Grid>
            </Grid>

            <Grid sx={{ mt: 1, mb: 3 }} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5">Note</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  id="outlined-multiline-static"
                  label="Note"
                  multiline
                  rows={4}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </Page>
  );
}
