import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  Switch,
  IconButton,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

import Page from "../components/Page";
import api from "../utils/api";

const style = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "25px",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Permissions() {
  const { userID } = useParams();
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [data, setData] = useState({});
  const [allows, setAllows] = useState([]);

  useEffect(() => {
    api
      .get("acl/map")
      .then((r) => {
        r.data.data.forEach((item, index) => {
          if (item._id === userID) {
            setData(item);
            setAllows(item.allows);
          }
        });
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(data);

  const sync = async () => {
    try {
      const res = await api.get("acl/syncPermissions");
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const onSwitch = async (e, id, column, methods) => {
    console.log(e, id, column, methods);

  

    switch (column) {
      case "get":
        methods.get = e;
        break;
      case "post":
        methods.post = e;

        break;
      case "put":
        methods.put = e;
        break;
      case "patch":
        methods.patch = e;

        break;
      case "delete":
        methods.delete = e;

        break;
      default:
        
    }

   

      const  aclMap ={
        'role':userID,
        'allows':[
          {
            'resource': id,
            'methods': methods , 
          }
        ]
      }

      console.log(aclMap)

    try {
      const res = await api.post('acl/map', {aclMap})
      console.log(res)
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <Page title="Role Permissions">
      <Container>
        <h1>Permissions for {data.role}</h1>
        <Button
          sx={{ marginBottom: "1rem", marginTop: "2rem" }}
          variant="contained"
          onClick={sync}
          startIcon={<SyncIcon />}
        >
          Sync
        </Button>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="right">Get</StyledTableCell>
                <StyledTableCell align="right">Post</StyledTableCell>
                <StyledTableCell align="right">Put</StyledTableCell>
                <StyledTableCell align="right">Patch</StyledTableCell>
                <StyledTableCell align="right">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allows.map((i) => (
                <StyledTableRow key={i.resource_id}>
                  <StyledTableCell component="th" scope="row">
                    {i.resource}
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Switch
                      defaultChecked={i.methods.get}
                      onChange={(e) => {
                        const column = "get";
                        onSwitch(
                          e.target.checked,
                          i.resource_id,
                          column,
                          i.methods
                        );
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Switch
                      defaultChecked={i.methods.post}
                      onChange={(e) => {
                        const column = "post";
                        onSwitch(
                          e.target.checked,
                          i.resource_id,
                          column,
                          i.methods
                        );
                      }}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Switch
                      defaultChecked={i.methods.put}
                      onChange={(e) => {
                        const column = "put";
                        onSwitch(
                          e.target.checked,
                          i.resource_id,
                          column,
                          i.methods
                        );
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Switch
                      defaultChecked={i.methods.patch}
                      onChange={(e) => {
                        const column = "patch";
                        onSwitch(
                          e.target.checked,
                          i.resource_id,
                          column,
                          i.methods
                        );
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Switch
                      defaultChecked={i.methods.delete}
                      onChange={(e) => {
                        const column = "delete";
                        onSwitch(
                          e.target.checked,
                          i.resource_id,
                          column,
                          i.methods
                        );
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: "2rem" }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add a Resource
          </Typography>
          <TextField
            pt="3rem"
            id="filled-basic"
            label="Resource"
            variant="outlined"
            onChange={(e) => {
              setResource(e.target.value);
            }}
          />
          <br/>
          <Button sx={{marginTop:'1rem'}} onClick={(e)=>{submit()}} variant="contained">Submit</Button>
        </Box>
      </Modal> */}
    </Page>
  );
}
