import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef, useMemo } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',

  bgcolor: 'background.paper',

  p: 4,
  borderRadius: '25px',
};

// ----------------------------------------------------------------------

export default function Contacts() {
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get('/homePage/contacts')
      .then((r) => {
        console.log(r.data.data);
        setData(r.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState('');
  const [data, setData] = useState([]);
  const [id, setId] = useState('');

  const user = useSelector((state) => state.auth.user);
  console.log({ user });
  const updateContact = async (id, con) => {
    const formData = {
      contactedBy: user?._id,
    };
    console.log({ formData });
    if (con) {
      formData.contacted = false;
    } else {
      formData.contacted = true;
    }

    console.log(formData);
    try {
      const res = await api.patch(`/homePage/contacts/${id}`, formData);
      console.log({ res });
      //   setData(res.data.data);
      //   handleCloseA();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteContact = async (id) => {
    try {
      const r = await api.delete(`/homePage/contacts/${id}`);
      console.log(r);
      setData(r.data.data.queriesData);
    } catch (err) {
      console.log(err);
    }
  };
  console.log({ data });
  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        description: 'Name',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'email',
        headerName: 'Email',
        description: 'email',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'countryCode',
        headerName: 'Country Code',
        description: 'country Code',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'mobile',
        headerName: 'Mobile',
        description: 'mobile',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'message',
        headerName: 'Message',
        description: 'message',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Contacted">
                  {params?.row?.reviewed ? (
                    <Switch
                      onClick={() => {
                        updateContact(params?.row?._id, params?.row?.contacted);
                      }}
                      checked
                    />
                  ) : (
                    <Switch
                      onClick={() => {
                        updateContact(params?.row?._id, params?.row?.contacted);
                      }}
                    />
                  )}
                </Tooltip>
              }
              label="Contacted"
              //   onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={() => deleteContact(params.id)}
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <Page title="Users">
      <Container>
        <h1>Contacts</h1>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      <Dialog
        sx={style}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit User Information
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
      </Dialog>
    </Page>
  );
}
