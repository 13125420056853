import { useState, useEffect, forwardRef, useMemo } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
// material
import { Container, Slide, Box, CircularProgress } from '@mui/material';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

export default function TicketCounts() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useLocation();

  const userId = state?.userId;
  const orgId = state?.orgId;
  console.log({ orgId });
  useEffect(() => {
    let url = '';
    if (orgId) {
      url = `/bd/BDmanagers/tickets/counts?userId=${userId}&orgId=${orgId}`;
    } else {
      url = `/bd/BDmanagers/tickets/counts?userId=${userId}`;
    }
    api
      .get(url)
      .then((r) => {
        setData(r.data.data.confDetails);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Conference',
        description: 'conference',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'startDate',
        headerName: 'startDate',
        description: 'startDate',
        type: 'date',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return `${new Date(params.value).toDateString()} - ${new Date(
            params.value
          ).toLocaleTimeString()}`;
        },
      },
      {
        field: 'endDate',
        headerName: 'endDate',
        description: 'endDate',
        type: 'date',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return `${new Date(params.value).toDateString()} - ${new Date(
            params.value
          ).toLocaleTimeString()}`;
        },
      },

      {
        field: 'paidTicketCount',
        headerName: 'Paid Ticket',
        description: 'Paid Ticket',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'freeTicketCount',
        headerName: 'Free Ticket',
        description: 'Free Ticket',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'soldTicket',
        headerName: 'Sold Ticket',
        description: 'Sold Ticket',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
    ],
    []
  );

  return (
    <Page title="Users">
      <Container>
        <h1>Ticket Counts</h1>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: { pageSize: 9, page: 0 },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{
              border: 2,
              borderColor: 'divider',
              '& .MuiDataGrid-cell': {
                borderBottom: 2,
                borderBottomColor: 'divider',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: 2,
                borderBottomColor: 'divider',
              },
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
            columns={columns}
            getRowId={(row) => row._id}
            rows={data}
          />
        )}
      </Container>
    </Page>
  );
}
