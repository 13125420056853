import { useDispatch } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  Switch,
  IconButton,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px',
};

// ----------------------------------------------------------------------

export default function Roles() {
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get('acl/roles')
      .then((r) => {
        setData(r.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState('');
  const [data, setData] = useState([]);
  const [id, setId] = useState('');

  const [openA, setOpenA] = useState(false);
  const handleOpenA = () => setOpenA(true);
  const handleCloseA = () => setOpenA(false);

  const onSwitch = async (e, id) => {
    // console.log(e, id);

    const role = {
      active: e,
    };

    try {
      const r = await api.patch(`acl/roles/${id}`, { role });
      console.log(r);
    } catch (err) {
      console.log(err);
    }
  };

  const submit = async () => {
    const formData = {
      role: {
        name: role,
      },
    };

    try {
      const res = await api.post('acl/roles', formData);
      if (res.data.status) {
        try {
          const r = await api.get('acl/roles');
          setData(r.data.data);
          handleClose();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const update = async () => {
    const formData = {
      role: {
        name: role,
      },
    };

    console.log(formData);
    try {
      const res = await api.patch(`acl/roles/${id}`, formData);
      if (res.data.status) {
        try {
          const r = await api.get('acl/roles');

          setData(r.data.data);
          handleCloseA();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Role',
        description: 'Name',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Show">
                  <VisibilityIcon />
                </Tooltip>
              }
              label="Show"
              onClick={() => {
                navigate(`/dashboard/permissions/${params.id}`);
              }}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Status">
                  {params?.row?.active ? (
                    <Switch
                      onClick={() => {
                        onSwitch(params.row.active, params.id);
                      }}
                      checked
                    />
                  ) : (
                    <Switch
                      onClick={() => {
                        onSwitch(params.row.active, params.id);
                      }}
                    />
                  )}
                </Tooltip>
              }
              label="Contact"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => {
                setId(params.id);
                setRole(params.row.name);
                handleOpenA();
              }}
            />,
          ];
        },
      },
    ],
    []
  );
  return (
    <Page title="Roles">
      <Container>
        <h1>Acl Roles</h1>
        <Button
          sx={{ marginBottom: '1rem', marginTop: '2rem' }}
          variant="contained"
          onClick={handleOpen}
          startIcon={<AddCircleIcon />}
        >
          Add role
        </Button>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add a role
          </Typography>
          <TextField
            pt="3rem"
            id="filled-basic"
            label="Role"
            variant="outlined"
            onChange={(e) => {
              setRole(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={(e) => {
              submit();
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openA}
        onClose={handleCloseA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Edit Role
          </Typography>
          <TextField
            pt="3rem"
            id="filled-basic"
            label="Role"
            value={role}
            variant="outlined"
            onChange={(e) => {
              setRole(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={update}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Page>
  );
}
