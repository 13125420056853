import { useEffect, useState } from 'react';

// @mui
import {
  Box,
  Card,
  Paper,
  Typography,
  CardHeader,
  CardContent,
  Grid,
  Container,
  Switch,
  Button,
  IconButton,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';

// components
import Page from '../components/Page';
import api from '../utils/api';

import Iconify from '../components/Iconify';

export default function ControlEmail() {
  const theme = useTheme();
  const [data, setData] = useState({});
  const [text, setText] = useState('');
  const [error, setError] = useState('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    api
      .get('bd/promotionalEmails')
      .then((r) => {
        setData(r.data.data.job);
      })
      .catch((err) => console.log(err));
  }, []);

  const controlEmail = async () => {
    try {
      let sendEmail = true;
      if (data?.length > 0) {
        sendEmail = false;
      }
      const r = await api.post(`bd/promotionalEmails`, { sendEmail });
      setData(r.data.data.job);
    } catch (err) {
      console.log(err);
    }
  };

  const sendAnnouncementEmail = async () => {
    if (!text.trim()) {
      setError('Email text required');
    }
    const formData = {
      message: text,
    };

    try {
      const res = await api.post(`/bd/emails/announcements`, formData);
      setText('');
      handleOpen();
    } catch (err) {
      setError(err.data.message);
    }
  };

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardHeader title={'Control Promotional Email'} />

            <CardContent>
              <Box
                sx={{
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                <Paper
                  key={''}
                  variant="outlined"
                  sx={{ py: 2.5, textAlign: 'center' }}
                >
                  <Box sx={{ mb: 0.5 }}>
                    {''}
                    {data?.length > 0 ? (
                      <Switch checked onClick={() => controlEmail()} />
                    ) : (
                      <Switch onClick={() => controlEmail()} />
                    )}
                  </Box>

                  <Typography variant="h6">
                    {data?.length > 0 ? 'Activated' : 'Deactivated'}
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {data?.length > 0
                      ? 'Email will sent every sunday'
                      : 'Promotional email deactivated.'}
                  </Typography>
                </Paper>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid sx={{ mt: 6 }} item xs={12} md={6} lg={4}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert
              onClose={handleClose}
              variant="filled"
              severity={error.length > 0 ? 'error' : 'success'}
              sx={{ width: '100%' }}
            >
              {error.length > 0 ? error : 'Email sent successfully.'}
            </Alert>
          </Snackbar>
          <Card>
            <CardHeader title={'Send Announcement Email'} />

            <CardContent>
              <Box
              // sx={{
              //   display: 'grid',
              //   gap: 2,
              //   gridTemplateColumns: 'repeat(3, 1fr)',
              // }}
              >
                <Box sx={{ mb: 0.5 }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Email"
                    fullWidth
                    multiline
                    rows={4}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </Box>
                <Box variant="p">{error}</Box>
                <Box sx={{ mt: 2 }}>
                  {text?.length > 0 ? (
                    <IconButton onClick={sendAnnouncementEmail}>
                      <SendIcon sx={{ fontSize: '34px', color: '#3f50b5' }} />
                    </IconButton>
                  ) : (
                    <IconButton disabled>
                      <SendIcon sx={{ fontSize: '34px' }} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}
