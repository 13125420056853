import { useDispatch } from 'react-redux';
import { useState, useEffect, forwardRef, useMemo } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import DeleteIcon from '@mui/icons-material/Delete';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',

  bgcolor: 'background.paper',

  p: 4,
  borderRadius: '25px',
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// ----------------------------------------------------------------------

export default function Feedbacks() {
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get('/bd/feedbacks')
      .then((r) => {
        console.log(r.data.data);
        setData(r.data.data.savedFeedBacks);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState('');
  const [data, setData] = useState([]);
  const [id, setId] = useState('');

  const update = async () => {
    const formData = {
      role: {
        name: role,
      },
    };

    console.log(formData);
    // try {
    //   const res = await api.patch(`acl/roles/${id}`, formData);
    //   if (res.data.status) {

    //     try{
    //       const r = await api.get("acl/roles")

    //       setData(r.data.data);
    //       handleCloseA()

    //     }
    //     catch(err) {
    //       console.log(err)

    //     }

    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const deleteFeedback = async (id) => {
    try {
      const r = await api.delete(`/bd/feedbacks/${id}`);
      setData(r.data.data.feedbacks);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'user',
        headerName: 'Name',
        description: 'Name',
        type: 'string',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return `${params.value.firstName} ${params.value.firstName}`;
        },
      },

      {
        field: 'title',
        headerName: 'Title',
        description: 'title',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      // {
      //   field: 'user',
      //   headerName: 'Email',
      //   description: 'email',
      //   type: 'string',
      //   minWidth: 250,
      //   flex: 1,
      //   valueFormatter: (params) => {
      //     return `${params.value.email}`;
      //   },
      // },

      {
        field: 'content',
        headerName: 'Content',
        description: 'content',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={() => deleteFeedback(params.id)}
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <Page title="Users">
      <Container>
        <h1>Feedbacks</h1>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      <Dialog
        sx={style}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit User Information
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
      </Dialog>
    </Page>
  );
}
