// component
import { useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },

  {
    title: 'Acl roles',
    path: '/dashboard/roles',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Acl resources',
    path: '/dashboard/resources',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Create Admin',
    path: '/dashboard/register',
    icon: getIcon('eva:people-fill'),
  },

  {
    title: 'BD Managers',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Amenities',
    path: '/dashboard/amenities',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Confemy Queries',
    path: '/dashboard/contacts',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Feedbacks',
    path: '/dashboard/feedbacks',
    icon: getIcon('eva:people-fill'),
  },

  {
    title: 'Potential Users',
    path: '/dashboard/potential-users',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Trending Confs',
    path: '/dashboard/trending-confs',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Trending Courses',
    path: '/dashboard/trending-courses',
    icon: getIcon('eva:people-fill'),
  },
  // {
  //   title: 'User Logs',
  //   path: '/dashboard/user-logs',
  //   icon: getIcon('eva:people-fill'),
  // },
  {
    title: 'Published Confs',
    path: '/dashboard/conferences/published',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Unpublished Confs',
    path: '/dashboard/conferences/unpublished',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Conf Counts',
    path: '/dashboard/conferences/counts',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Control Emails',
    path: '/dashboard/emails',
    icon: getIcon('eva:people-fill'),
  },
];

export default navConfig;
