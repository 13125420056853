import { useDispatch } from 'react-redux';
import { useState, useEffect, forwardRef } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Delete from '@mui/icons-material/Delete';
import { loadUserAction } from '../redux/auth/authAction';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',

  bgcolor: 'background.paper',

  p: 4,
  borderRadius: '25px',
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// ----------------------------------------------------------------------

export default function UserLogs() {
  const navigate = useNavigate();

  useEffect(() => {
    api
      .get('/users/apis/logs')
      .then((r) => {
        console.log(r.data.data);
        setData(r.data.data.userLogs);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState('');
  const [data, setData] = useState([]);
  const [id, setId] = useState('');

  const update = async () => {
    const formData = {
      role: {
        name: role,
      },
    };

    console.log(formData);
    // try {
    //   const res = await api.patch(`acl/roles/${id}`, formData);
    //   if (res.data.status) {

    //     try{
    //       const r = await api.get("acl/roles")

    //       setData(r.data.data);
    //       handleCloseA()

    //     }
    //     catch(err) {
    //       console.log(err)

    //     }

    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const deleteFeedback = async (id) => {
    try {
      const r = await api.delete(`/bd/feedbacks/${id}`);
      setData(r.data.data.feedbacks);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Page title="User Logs">
      <Container>
        <h1>User Logs</h1>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Resource</StyledTableCell>
                <StyledTableCell>Browser</StyledTableCell>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Method</StyledTableCell>
                <StyledTableCell align="right">Req Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 &&
                data.map((i) => {
                  return (
                    <StyledTableRow key={i._id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{ wordBreak: 'break-all' }}
                        // sx={{ width: '60px' }}
                      >
                        {`${i.resource}`}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {i?.browserName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {i?.user?.firstName} {i?.user?.lastName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {i?.method}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {new Date(i?.reqTime).toDateString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}
