import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  Fab,
  Chip,
  Switch,
  IconButton,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Avatar,
  Card,
  Stack,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { loadUserAction } from '../redux/auth/authAction';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px',
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// ----------------------------------------------------------------------

export default function Amenities(styles) {
  useEffect(() => {
    api
      .get('/conferences/amenities')
      .then((r) => {
        setData(r.data.data.amenities);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [resourceName, setResourceName] = useState('');
  const [openA, setOpenA] = useState(false);
  const handleOpenA = () => setOpenA(true);
  const handleCloseA = () => setOpenA(false);

  const [data, setData] = useState([]);
  const [id, setId] = useState('');

  const update = async () => {
    const resource = {
      name: resourceName,
    };

    try {
      const res = await api.patch(`/conferences/amenities/${id}`, { resource });
      if (res.data.status) {
        try {
          const r = await api.get('acl/resources');

          setData(r.data.data);
          handleCloseA();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [files, setFiles] = useState([]);
  const [fileUpload, setFilesForUpload] = useState([]);

  const submit = async () => {
    console.log('submit run');
    if (!fileUpload.length > 0) {
      console.log('runn');
      return;
    }

    try {
      const formData = new FormData();
      console.log(fileUpload[0]);
      formData.append('file', fileUpload[0]);

      const fileRes = await api.post('fileUploads', formData);
      console.log(fileRes.data);
      const amenity = {
        name: resourceName,
        data: fileRes.data.data,
      };

      const res = await api.post('conferences/amenities', { amenity });
      if (res.data.status) {
        try {
          const r = await api.get('conferences/amenities');
          setData(r.data.data.amenities);
          setFiles([]);
          handleClose();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log({ files });
    // Make sure to revoke the data uris to avoid memory leaks
  }, [files]);
  return (
    <Page title="Amenities">
      <Container>
        <h1>Amenities</h1>
        <Button
          sx={{ marginBottom: '1rem', marginTop: '2rem' }}
          variant="contained"
          onClick={handleOpen}
          startIcon={<AddCircleIcon />}
        >
          Add Amenities
        </Button>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Amenities</StyledTableCell>
                <StyledTableCell align="left">Icon</StyledTableCell>

                <StyledTableCell align="left">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((i) => (
                <StyledTableRow key={i._id}>
                  <StyledTableCell component="th" scope="row">
                    {i.label}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {' '}
                    <Avatar
                      //   variant={'rounded'}
                      alt="The image"
                      src={i.icon}
                      style={{
                        width: 24,
                        height: 24,
                      }}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    {' '}
                    <IconButton
                      onClick={() => {
                        console.log(i);
                        setId(i.value);
                        setResourceName(i.label);
                        handleOpenA();
                      }}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>{' '}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add a Amenity
          </Typography>
          <Fab
            color="primary"
            aria-label="add-image"
            sx={{
              position: 'fixed',
              top: 90,
              left: 20,
              overflow: 'hidden',
              boxShadow: 'none',
            }}
          >
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              multiple
              onChange={(e) => {
                setFiles(URL.createObjectURL(e.target.files[0]));
                setFilesForUpload(e.target.files);
                // setFiles(e.target.files);
              }}
              style={{
                position: 'absolute',
                top: '-35px',
                left: 0,
                height: 'calc(100% + 36px)',
                width: 'calc(100% + 5px)',
                outline: 'none',
                boxShadow: 'none',
              }}
            />

            {files.length < 1 ? (
              <PhotoCamera />
            ) : (
              <Avatar
                //   variant={'rounded'}
                alt="The image"
                src={files}
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            )}
          </Fab>

          <TextField
            pt="3rem"
            id="filled-basic"
            label="Amenity"
            variant="outlined"
            onChange={(e) => {
              setResourceName(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={(e) => {
              submit();
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openA}
        onClose={handleCloseA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Edit Aminity
          </Typography>
          <TextField
            pt="3rem"
            id="filled-basic"
            label="Amenity"
            variant="outlined"
            value={resourceName}
            onChange={(e) => {
              setResourceName(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={update}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Page>
  );
}
