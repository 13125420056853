import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LinearProgress, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/system";
import api from "../utils/api";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function Verification() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");

  const handleClose = () => {
    setOpen(false);
    navigate(link);
  };

  useEffect(() => {
    api
      .get(`BDmanagers/verify?verifyToken=${token}`)
      .then((r) => {
        console.log(r);
        setLoading(false);
        setMessage("Your Email has been verified");
        setLink("/");
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setMessage(err.response.data.message);
        setLink("");
        setOpen(true);
      });
  }, []);

  return (
    <Box>
      {loading ? (
        <LinearProgress />
      ) : (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Verification Status"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Okay</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
