import { useState, useEffect, forwardRef, useMemo } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
// material
import { Container, Slide, Box, CircularProgress } from '@mui/material';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

export default function ConfCounts() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    api
      .get('/bd/BDmanagers/conferences/counts')
      .then((r) => {
        setData(r.data.data.countsData);
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        description: 'Name',
        type: 'string',
        minWidth: 250,
      },

      {
        field: 'email',
        headerName: 'Email',
        description: 'email',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'organization',
        headerName: 'organization',
        description: 'email',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'count',
        headerName: 'Org Conf',
        description: 'Org Conf',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'userConfCount',
        headerName: 'User Conf',
        description: 'User Conf',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
    ],
    []
  );

  return (
    <Page title="Conf Counts">
      <Container>
        <h1>Conference hosted by user and organization.</h1>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: { pageSize: 9, page: 0 },
              },
            }}
            onRowClick={(params) =>
              navigate('/dashboard/conferences/ticket-counts', {
                state: {
                  userId: params?.row?.userId,
                  orgId: params?.row?.organizationId,
                },
              })
            }
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{
              border: 2,
              borderColor: 'divider',
              '& .MuiDataGrid-cell': {
                borderBottom: 2,
                borderBottomColor: 'divider',
              },
              '& .MuiDataGrid-columnHeaders': {
                borderBottom: 2,
                borderBottomColor: 'divider',
              },
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
            }}
            columns={columns}
            getRowId={(row) => row.id}
            rows={data}
          />
        )}
      </Container>
    </Page>
  );
}
