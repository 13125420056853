import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef, useMemo } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
  Tooltip,
  Alert,
} from '@mui/material';

import OpenInBrowser from '@mui/icons-material/OpenInBrowser';
import Publish from '@mui/icons-material/Publish';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
// import { loadUserAction } from '../redux/auth/authAction';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

export default function UnpublishedConference() {
  useEffect(() => {
    api
      .get('/bd/conferences?type=unpublished')
      .then((r) => {
        setData(r.data.data.conferences);
      })
      .catch((err) => console.log(err));
  }, []);

  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const updateConf = async (id, status) => {
    const conferenceDetails = {
      reviewedBy: user?._id,
    };

    if (status) {
      conferenceDetails.reviewed = false;
    } else {
      conferenceDetails.reviewed = true;
    }
    console.log({ conferenceDetails, id });
    try {
      const res = await api.patch(`/bd/conferences/${id}?type=unpublished`, {
        conferenceDetails,
      });
      console.log({ res });
      setData(res.data.data.conferences);
      //   handleCloseA();
    } catch (err) {
      console.log(err);
    }
  };
  const publichConf = async (conferenceId) => {
    const formData = {
      conferenceDetails: {
        userId: user?._id,
      },
    };

    try {
      const res = await api.patch(
        `/bd/conferences/${conferenceId}/publish?type=unpublished`,
        formData
      );

      setData(res.data.data.conferences);
    } catch (err) {
      setError(err.data.message);
    }
  };

  const deleteConf = async (confId) => {
    try {
      const res = await api.delete(`/bd/conferences/${confId}?type=published`);

      setData(res.data.data.conferences);
    } catch (err) {
      setError(err.data.message);
    }
  };
  const rejectConf = async (conferenceId) => {
    const formData = {
      conferenceDetails: {
        userId: user?._id,
      },
    };

    try {
      const res = await api.patch(
        `/bd/conferences/${conferenceId}/reject?type=unpublished`,
        formData
      );

      setData(res.data.data.conferences);
    } catch (err) {
      setError(err.data.message);
    }
  };
  const columns = useMemo(
    () => [
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        description: 'Published',
        type: 'date',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return `${new Date(params.value).toDateString()} - ${new Date(
            params.value
          ).toLocaleTimeString()}`;
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        description: 'Title',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'hostedBy',
        headerName: 'Hosted By',
        description: 'Hosted By',
        type: 'string',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return params?.value?.user?.email
            ? `${params?.value?.user?.email} (Self)`
            : `${params?.value?.organization?.name} (Org)`;
        },
      },
      {
        field: 'inActiveNote',
        headerName: 'Note',
        description: 'Note',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'BDActiveStatus',
        headerName: 'Active Status',
        description: 'Active Status',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'activeRequest',
        headerName: 'Active Req',
        description: 'Active Req',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            // <GridActionsCellItem
            //   icon={
            //     <Tooltip title="Delete Department">
            //       <Delete />
            //     </Tooltip>
            //   }
            //   label="Delete"
            //   //   onClick={openDeleteDialog(params.id, params.row)}
            // />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Review">
                  {params?.row?.reviewed ? (
                    <Switch
                      onClick={() => {
                        console.log({ params });
                        updateConf(params.id, params?.row?.reviewed);
                      }}
                      checked
                    />
                  ) : (
                    <Switch
                      onClick={() => {
                        updateConf(params?.id, params?.row?.reviewed);
                      }}
                    />
                  )}
                </Tooltip>
              }
              label="Reviewed"
              //   onClick={editRow(params.id, params.row)}
              // showInMenu
            />,

            <GridActionsCellItem
              icon={
                <Tooltip title="Show">
                  <OpenInBrowser />
                </Tooltip>
              }
              label="Show"
              onClick={() => {
                console.log({ params });
                window.open(
                  `https://staging.confemy.com/search-conference/${params?.row?._id}`
                );
              }}
              // showInMenu
            />,

            <GridActionsCellItem
              icon={
                <Tooltip title="Publish">
                  <Publish />
                </Tooltip>
              }
              label="Review"
              onClick={() => {
                publichConf(params.id);
              }}
              // showInMenu
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Reject">
                  <CancelIcon />
                </Tooltip>
              }
              label="Reject"
              onClick={() => {
                rejectConf(params.id);
              }}
              // showInMenu
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={() => {
                deleteConf(params.id);
              }}
              // showInMenu
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <Page title="Users">
      <Container>
        <h1>Unpublished Conferences</h1>
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
    </Page>
  );
}
