import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
// routes

// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// actions
import { loadUserAction } from './redux/auth/authAction';

import DashboardLayout from './layouts/dashboard';
import DashboardApp from './pages/DashboardApp';
import Roles from './pages/Roles';
import Login from './pages/Login';
import Register from './pages/Register';
import NotFound from './pages/Page404';
import IsAuth from './components/IsAuth';
// import api from "./utils/api";
import Permissions from './pages/Permissions';
import Resources from './pages/Resources';
import Verification from './pages/Verification';
import Users from './pages/Users';
import Amenities from './pages/Amenities';
import Contacts from './pages/Contacts';
import Feedbacks from './pages/Feedbacks';
import PotentialUsers from './pages/PotentialUsers';
import TrendingConfs from './pages/TrendingConfs';
import UserLogs from './pages/UserLogs';
import Conferences from './pages/Conferences';
import UnpublishedConference from './pages/UnpublishConfs';
import ConfCounts from './pages/ConfCounts';
import TicketCounts from './pages/TicketCounts';
import ControlEmail from './pages/ControlEmail';
import TrendingCourses from './pages/TrendingCourses';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUserAction());
  }, []);
  const user = useSelector((state) => state.auth.user);
  console.log({ user });
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Routes>
        <Route element={<IsAuth />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="app" element={<DashboardApp />} />
            {user?.role === 'bd_admin' && (
              <Route path="roles" element={<Roles />} />
            )}
            <Route path="permissions/:userID" element={<Permissions />} />
            {user?.role === 'bd_admin' && (
              <Route path="resources" element={<Resources />} />
            )}
            <Route path="register" element={<Register />} />
            <Route path="users" element={<Users />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="feedbacks" element={<Feedbacks />} />
            <Route path="amenities" element={<Amenities />} />
            <Route path="potential-users" element={<PotentialUsers />} />
            <Route path="trending-confs" element={<TrendingConfs />} />
            <Route path="trending-courses" element={<TrendingCourses />} />
            <Route path="user-logs" element={<UserLogs />} />
            <Route path="conferences/published" element={<Conferences />} />
            <Route path="conferences/counts" element={<ConfCounts />} />
            <Route path="emails" element={<ControlEmail />} />
            <Route
              path="conferences/unpublished"
              element={<UnpublishedConference />}
            />
            <Route
              path="conferences/ticket-counts"
              element={<TicketCounts />}
            />
          </Route>
        </Route>

        <Route path="/" element={<Login />} />
        <Route path="/verify/email/:token" element={<Verification />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </ThemeProvider>
  );
}
