import api from '../../utils/api';
import {
  AUTH_ERROR,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from './authTypes';

// loaduser

export const loadUserAction = () => async (dispatch) => {
  try {
    const response = await api.get('user?userType=bdManager');
    console.log('Response LoadUser:', response);
    dispatch({
      type: USER_LOADED,
      payload: response.data.data.user,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const loginAction = (userData) => async (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: userData,
  });
};

export const logoutAction = () => async (dispatch) => {
  await api
    .get('logout')
    .then((r) => console.log(r))
    .catch((err) => console.log(err));
  dispatch({
    type: LOGOUT,
  });
};
