import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef, useMemo } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Tooltip,
  Switch,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import AddCircleIcon from '@mui/icons-material/AddCircle';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';
import UpdateBDmanagerForm from '../components/UpdateBDManager';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px',
};

export default function Users() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.user);
  useEffect(() => {
    api
      .get('bd/BDmanagers')
      .then((r) => {
        console.log(r.data.data);
        setData(r.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState('');
  const [data, setData] = useState([]);
  const [user, setUser] = useState({});
  const [id, setId] = useState('');
  const [error, setError] = useState('');
  const [modalState, setModalState] = useState(false);

  const deleteManager = async (bdManagerId) => {
    try {
      const res = await api.delete(
        `bd/BDmanagers/${bdManagerId}?userId=${userData?._id}`
      );

      setData(res.data.data);
    } catch (err) {
      setError(err.data.message);
    }
  };
  const updateBdManager = async (managerId, active) => {
    const formData = {
      managerData: {},
    };

    if (active) {
      formData.managerData.active = false;
    } else {
      formData.managerData.active = true;
    }

    try {
      const res = await api.patch(
        `bd/BDmanagers/${managerId}?userId=${userData?._id}`,
        formData
      );

      setData(res.data.data.bdManagers);
    } catch (err) {
      setError(err.data.message);
    }
  };
  const columns = useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'Name',
        description: 'Name',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'email',
        headerName: 'Email',
        description: 'email',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'emailVerified',
        headerName: 'Email verified',
        description: 'email',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Suspend">
                  {params?.row?.active ? (
                    <Switch
                      onClick={() => {
                        updateBdManager(params.id, params?.row?.active);
                      }}
                      checked
                    />
                  ) : (
                    <Switch
                      onClick={() => {
                        updateBdManager(params.id, params?.row?.active);
                      }}
                    />
                  )}
                </Tooltip>
              }
              label="Suspend"
              //   onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => {
                setUser(params.row);
                setModalState(true);
              }}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={() => deleteManager(params.id)}
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <Page title="Users">
      <Container>
        <h1>Users</h1>
        <Button
          sx={{ marginBottom: '1rem', marginTop: '2rem' }}
          variant="contained"
          onClick={() => navigate('/dashboard/register')}
          startIcon={<AddCircleIcon />}
        >
          Add User
        </Button>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      <Modal
        open={modalState}
        onClose={() => {
          setModalState(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Update BD manager
          </Typography>
          {/* {error && (
            <Box sx={{ marginBottom: 4 }}>
              {' '}
              <Alert severity="error">{error}</Alert>
            </Box>
          )} */}

          <UpdateBDmanagerForm
            setData={setData}
            setModalState={setModalState}
            user={user}
          />
        </Box>
      </Modal>
    </Page>
  );
}
