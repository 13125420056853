import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';

// component
import Iconify from './Iconify';
import api from '../utils/api';

// ----------------------------------------------------------------------

export default function UpdateBDmanagerForm({ user, setData, setModalState }) {
  useEffect(() => {
    api
      .get(`acl/roles?type=bdManager`)
      .then((r) => {
        setRoles(r.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log({ user });
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.user);
  const [showPassword, setShowPassword] = useState(false);
  const [cpass, setCpass] = useState(false);
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Last name required'),
    // email: Yup.string()
    //   .email('Email must be a valid email address')
    //   .required('Email is required'),
    password: Yup.string(),
    role: Yup.string().required('Role is Required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      password: '',
      confirmPassword: '',
      role: user?.role?._id,
    },

    validationSchema: RegisterSchema,
    onSubmit: async (values, actions) => {
      const formData = {
        managerData: {
          ...values,
        },
      };

      if (formData?.managerData?.password) {
        formData.managerData.changePassword = true;
      }
      console.log({ formData });
      try {
        const res = await api.patch(
          `bd/BDmanagers/${user?._id}?userId=${userData?._id}`,
          formData
        );
        console.log(res);
        setData(res.data.data.bdManagers);
        setOpen(true);
        setModalState(false);
        // actions.formRes
      } catch (err) {
        console.log(err.response.data.message);

        actions.setFieldError('password', err.response.data.message);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            type="email"
            label="Email address"
            disabled
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <FormControl
            error={formik.touched.role && Boolean(formik.errors.role)}
            fullWidth
          >
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              {...getFieldProps('role')}
              // error={Boolean(touched.role && errors.role)}
              helperText={touched.role && formik.errors.role}
              label="Role"
              // value={user.role?._id || ''}
            >
              {roles.map((i) => (
                <MenuItem key={i._id} value={i._id}>
                  {i.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formik.touched.role && formik.errors.role}
            </FormHelperText>
          </FormControl>

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={cpass ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setCpass((prev) => !prev)}
                  >
                    <Iconify
                      icon={cpass ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            style={{ background: '#70E994' }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Update
          </LoadingButton>
        </Stack>
      </Form>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Manager updated successfully
        </Alert>
      </Snackbar>
    </FormikProvider>
  );
}
