import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef, useMemo } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
  Tooltip,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OpenInBrowser from '@mui/icons-material/OpenInBrowser';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
// import { loadUserAction } from '../redux/auth/authAction';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

const style = {
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px',
};

export default function Conferences() {
  useEffect(() => {
    api
      .get('/bd/conferences?type=published')
      .then((r) => {
        setData(r.data.data.conferences);
      })
      .catch((err) => console.log(err));
  }, []);

  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState('');
  const [conferenceId, setConferenceId] = useState('');

  const user = useSelector((state) => state.auth.user);
  const updateConf = async (id, status) => {
    const conferenceDetails = {
      reviewedBy: user?._id,
    };

    if (status) {
      conferenceDetails.reviewed = false;
    } else {
      conferenceDetails.reviewed = true;
    }
    console.log({ conferenceDetails, id });
    try {
      const res = await api.patch(`/bd/conferences/${id}?type=published`, {
        conferenceDetails,
      });
      console.log({ res });
      setData(res.data.data.conferences);
      //   handleCloseA();
    } catch (err) {
      console.log(err);
    }
  };
  const unpublishConf = async () => {
    if (!note.trim()) {
      setError('Note required');
    }
    const formData = {
      conferenceDetails: {
        userId: user?._id,
        note,
      },
    };

    try {
      const res = await api.patch(
        `/bd/conferences/${conferenceId}/unpublish?type=published`,
        formData
      );

      setData(res.data.data.conferences);
      setModalState(false);
    } catch (err) {
      setError(err.data.message);
    }
  };

  const deleteConf = async (confId) => {
    try {
      const res = await api.delete(`/bd/conferences/${confId}?type=published`);
      console.log({ res });
      setData(res.data.data.conferences);
    } catch (err) {
      setError(err.data.message);
    }
  };
  const columns = useMemo(
    () => [
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        description: 'Published',
        type: 'date',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return `${new Date(params.value).toDateString()} - ${new Date(
            params.value
          ).toLocaleTimeString()}`;
        },
      },
      {
        field: 'title',
        headerName: 'Title',
        description: 'Title',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'hostedBy',
        headerName: 'Hosted By',
        description: 'Hosted By',
        type: 'string',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return params?.value?.user?.email
            ? `${params?.value?.user?.email} (Self)`
            : `${params?.value?.organization?.name} (Org)`;
        },
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            // <GridActionsCellItem
            //   icon={
            //     <Tooltip title="Delete Department">
            //       <Delete />
            //     </Tooltip>
            //   }
            //   label="Delete"
            //   //   onClick={openDeleteDialog(params.id, params.row)}
            // />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Review">
                  {params?.row?.reviewed ? (
                    <Switch
                      onClick={() => {
                        console.log({ params });
                        updateConf(params.id, params?.row?.reviewed);
                      }}
                      checked
                    />
                  ) : (
                    <Switch
                      onClick={() => {
                        updateConf(params?.id, params?.row?.reviewed);
                      }}
                    />
                  )}
                </Tooltip>
              }
              label="Reviewed"
              //   onClick={editRow(params.id, params.row)}
              // showInMenu
            />,

            <GridActionsCellItem
              icon={
                <Tooltip title="Show">
                  <OpenInBrowser />
                </Tooltip>
              }
              label="Show"
              onClick={() => {
                console.log({ params });
                window.open(
                  `https://staging.confemy.com/search-conference/${params?.row?._id}`
                );
              }}
              // showInMenu
            />,

            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <Edit />
                </Tooltip>
              }
              label="Review"
              onClick={() => {
                setConferenceId(params.id);
                setModalState(true);
              }}
              // showInMenu
            />,

            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <Delete />
                </Tooltip>
              }
              label="Review"
              onClick={() => {
                deleteConf(params.id);
              }}
              // showInMenu
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <Page title="Users">
      <Container>
        <h1>Published Conferences</h1>
        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      {modalState && (
        <Modal
          open={modalState}
          onClose={() => {
            setModalState(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              sx={{ paddingBottom: '2rem' }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Unpublish Conference
            </Typography>
            {error && (
              <Box sx={{ marginBottom: 4 }}>
                {' '}
                <Alert severity="error">{error}</Alert>
              </Box>
            )}

            <TextField
              id="outlined-multiline-static"
              label="Note"
              multiline
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
            <br />
            <Button
              sx={{ marginTop: '1rem' }}
              onClick={unpublishConf}
              variant="contained"
            >
              Unpublish
            </Button>
          </Box>
        </Modal>
      )}
    </Page>
  );
}
