import { useDispatch } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  Switch,
  IconButton,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { loadUserAction } from '../redux/auth/authAction';

// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px',
};

export default function Resources() {
  useEffect(() => {
    api
      .get('acl/resources')
      .then((r) => {
        setData(r.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [resourceName, setResourceName] = useState('');
  const [openA, setOpenA] = useState(false);
  const handleOpenA = () => setOpenA(true);
  const handleCloseA = () => setOpenA(false);

  const [data, setData] = useState([]);
  const [id, setId] = useState('');

  const update = async () => {
    const resource = {
      name: resourceName,
    };

    try {
      const res = await api.patch(`acl/resource/${id}`, { resource });
      if (res.data.status) {
        try {
          const r = await api.get('acl/resources');

          setData(r.data.data);
          handleCloseA();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submit = async () => {
    const resourses = {
      newApiResourses: [resourceName],
    };

    try {
      const res = await api.post('acl/resources', { resourses });
      if (res.data.status) {
        try {
          const r = await api.get('acl/resources');
          setData(r.data.data);
          handleClose();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        description: 'Name',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'alterName',
        headerName: 'Alter Name',
        description: 'email',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => {
                setId(params.id);
                setResourceName(params.row.name);
                handleOpenA();
              }}
            />,
          ];
        },
      },
    ],
    []
  );

  return (
    <Page title="Resources">
      <Container>
        <h1>Acl Resources</h1>
        <Button
          sx={{ marginBottom: '1rem', marginTop: '2rem' }}
          variant="contained"
          onClick={handleOpen}
          startIcon={<AddCircleIcon />}
        >
          Add Resource
        </Button>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Add a Resource
          </Typography>
          <TextField
            pt="3rem"
            id="filled-basic"
            label="Resource"
            variant="outlined"
            onChange={(e) => {
              setResourceName(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={(e) => {
              submit();
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openA}
        onClose={handleCloseA}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Edit Resource
          </Typography>
          <TextField
            pt="3rem"
            id="filled-basic"
            label="Resource"
            variant="outlined"
            value={resourceName}
            onChange={(e) => {
              setResourceName(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={update}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Page>
  );
}
