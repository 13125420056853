import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Page404 from '../pages/Page404';
import Login from '../pages/Login';
import api from '../utils/api';

const useAuth = () => {
  const [auth, setAuth] = useState(false);

  api
    .get('user?userType=bdManager')
    .then((r) => {
      if (r.data.data.user) {
        if (r.data.data.user.role !== 'user') {
          setAuth(true);
        } else {
          console.log('user');
        }
      }
    })
    .catch((err) => {
      console.log(err);
      setAuth(false);
    });

  return auth;
};

export default function IsAuth() {
  const authority = useAuth();

  return authority ? <Outlet /> : <h1>You are not authorized</h1>;
}
