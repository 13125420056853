import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, forwardRef, useMemo } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';

// material
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  tableCellClasses,
  styled,
  Container,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Box,
  Modal,
  Typography,
  TextField,
  Switch,
  Alert,
  Tooltip,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { AppWidgetSummary } from '../sections/@dashboard/app';
// components
import Page from '../components/Page';

// mock
import api from '../utils/api';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '25px',
};

// ----------------------------------------------------------------------

export default function TrendingCourses() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [role, setRole] = useState('');
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState([]);
  const [limitId, setLimitId] = useState({});
  const [modalState, setModalState] = useState(false);
  const [modalLimitState, setModalLimitState] = useState(false);
  const [id, setId] = useState('');
  const [mediaId, setMediaId] = useState('');
  const [rank, setRank] = useState('');
  const [limitData, setLimitData] = useState('');
  const [error, setError] = useState('');

  const user = useSelector((state) => state.auth.user);

  const updateConf = async () => {
    if (rank <= 0) {
      setError('Rank must be greater than 0');
    }
    const userDetails = {
      rank,
    };

    try {
      const res = await api.patch(
        `/homePage/trendings/media/${mediaId}`,
        userDetails
      );
      console.log({ res });
      setData(res.data.data.courses);
      setModalState(false);
      //   handleCloseA();
    } catch (err) {
      setError(err.data.message);
    }
  };

  const updateLimit = async () => {
    if (limitData <= 0) {
      setError('Limit must be greater than 0');
    }
    const userDetails = {
      trendingCourseLimit: limitData,
    };

    try {
      const res = await api.patch(
        `/limits/trendingConfs/${limitId}`,
        userDetails
      );

      setLimit([res.data.data.updatedLimit]);
      setModalLimitState(false);
      setError('');
      //   handleCloseA();
    } catch (err) {
      setError(err.data.message);
    }
  };
  const columns = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Conference',
        description: 'conference',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'trendingRank',
        headerName: 'Rank',
        description: 'rank',
        type: 'string',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return params?.value ? params.value : 0;
        },
      },

      {
        field: 'hostedBy',
        headerName: 'Hosted By',
        description: 'hostedBy',
        type: 'string',
        minWidth: 250,
        flex: 1,
        valueFormatter: (params) => {
          return params?.value?.user
            ? `${params?.value?.user?.firstName} ${params?.value?.user?.lastName}`
            : params?.value?.organization?.name;
        },
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => {
                setRank(
                  params.row?.trendingRank ? params.row?.trendingRank : 0
                );
                setMediaId(params.row?._id);
                setModalState(true);
              }}
              // showInMenu
            />,
          ];
        },
      },
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        field: 'trendingCourseLimit',
        headerName: 'Treding Limit',
        description: 'conference',
        type: 'string',
        minWidth: 250,
        flex: 1,
      },

      {
        field: 'actions',
        headerName: 'Actions',
        description: 'Modify table',
        type: 'actions',
        minWidth: 250,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={() => {
                setLimitData(
                  params.row?.trendingConfsLimit
                    ? params.row?.trendingConfsLimit
                    : 0
                );
                setLimitId(params.row?._id);
                setModalLimitState(true);
              }}
              // showInMenu
            />,
          ];
        },
      },
    ],
    []
  );
  useEffect(() => {
    api
      .get('/limits/trendingConfs')
      .then((r) => {
        setLimit([r.data.data.limit]);
      })
      .catch((err) => console.log(err));
    api
      .get('/homePage/courses/rank')
      .then((r) => {
        console.log({ r });
        setData(r.data.data.courses);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Page title="Trending Confs">
      <Container>
        <h1>Trending Courses Limit</h1>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          sx={{
            border: 2,
            mb: 3,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns2}
          getRowId={(row) => row._id}
          rows={limit}
        />
        <h1>Trending Courses</h1>

        <DataGrid
          initialState={{
            pagination: {
              paginationModel: { pageSize: 9, page: 0 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            border: 2,
            borderColor: 'divider',
            '& .MuiDataGrid-cell': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 2,
              borderBottomColor: 'divider',
            },
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
          columns={columns}
          getRowId={(row) => row._id}
          rows={data}
        />
      </Container>
      <Modal
        open={modalState}
        onClose={() => {
          setModalState(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Update Rank
          </Typography>
          {error && (
            <Box sx={{ marginBottom: 4 }}>
              {' '}
              <Alert severity="error">{error}</Alert>
            </Box>
          )}

          <TextField
            pt="3rem"
            id="filled-basic"
            label="Role"
            value={rank}
            type="number"
            variant="outlined"
            onChange={(e) => {
              setRank(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={updateConf}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Modal
        open={modalLimitState}
        onClose={() => {
          setModalLimitState(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ paddingBottom: '2rem' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Update Limit
          </Typography>
          {error && (
            <Box sx={{ marginBottom: 4 }}>
              {' '}
              <Alert severity="error">{error}</Alert>
            </Box>
          )}

          <TextField
            pt="3rem"
            id="filled-basic"
            label="Role"
            value={limitData}
            type="number"
            variant="outlined"
            onChange={(e) => {
              setLimitData(e.target.value);
            }}
          />
          <br />
          <Button
            sx={{ marginTop: '1rem' }}
            onClick={updateLimit}
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Page>
  );
}
